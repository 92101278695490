import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setUserSetting } from 'actions/userSettings';
import { getUserSettingsFromState } from 'components/Settings/UserSettings/userSettingsType';
import { PanelButton } from '../BoolErrorMessage';

const Container = styled.div`
  margin-top: 5px;
  max-width: 930px;
  }
`;
function DefaultCategoryMessage({ categories, onClose }) {
  const dispatch = useDispatch();
  const onDontShowAgain = () => {
    dispatch(
      setUserSetting(
        getUserSettingsFromState.DisplayDefaultCategoriesErrorMessage,
        false
      )
    );
    onClose();
  };

  const onYes = () => {
    dispatch(
      setUserSetting(
        getUserSettingsFromState.DefaultSearchCategories,
        Object.keys(categories).filter(k => categories[k])
      )
    );
    onClose();
  };

  return (
    <Container>
      <Alert
        style={{ height: '100%', paddingBottom: '7px', paddingTop: '7px' }}
        description={
          <>
            Would you like to set the selected categories as your{' '}
            <b>default categories</b>? This can be changed later in settings
          </>
        }
        type='error'
        action={
          <div
            style={{
              display: 'flex',
            }}
          >
            <PanelButton size='small' onClick={onYes}>
              Yes
            </PanelButton>
            <PanelButton size='small' onClick={onClose}>
              No
            </PanelButton>
            <PanelButton
              size='small'
              style={{ marginLeft: 5 }}
              onClick={onDontShowAgain}
            >
              Don't ask again
            </PanelButton>
          </div>
        }
      />
    </Container>
  );
}

DefaultCategoryMessage.propTypes = {
  categories: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DefaultCategoryMessage;
