import { Icon, Link, Clamp, Highlight } from 'components/common';
import {
  InnerContainerRow,
  ActivityDetail,
  ActivityData,
  MetadataLabel,
  IconWrapper,
  ContentWrapper,
  MetadataValue,
} from './styled';
import { Container } from '../styled';
import { colours } from 'utils/theme';
import { format } from 'utils/date';
import { stripHtml } from 'utils/string';

import OwnersDetails from './OwnersDetail';

const Publication = ({
  articleTitle,
  journalTitle,
  highlights,
  citationId,
  date,
  researchers,
  organisations,
}) => {
  return (
    <Container>
      <InnerContainerRow>
        <IconWrapper>
          <Icon
            color={colours.teal}
            size='1x'
            icon='book'
            style={{ justifyContent: 'start' }}
          />
        </IconWrapper>
        <ContentWrapper>
          <Link to={`publication/${citationId}`} showIconHint>
            <Clamp text={articleTitle} title={stripHtml(articleTitle)} isHtml />
          </Link>
          <ActivityDetail>
            <Highlight highlights={highlights} />
            <InnerContainerRow>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='calendar'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Date Published:
                </MetadataLabel>
                <MetadataValue>{format(date, 'D MMM YYYY')}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='book'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Journal:{' '}
                </MetadataLabel>
                <MetadataValue>{journalTitle}</MetadataValue>
              </ActivityData>
            </InnerContainerRow>
          </ActivityDetail>
          <OwnersDetails
            researchers={researchers}
            organisations={organisations}
          />
        </ContentWrapper>
      </InnerContainerRow>
    </Container>
  );
};

export default Publication;
