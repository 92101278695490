import { getUserSettingsFromState } from 'components/Settings/UserSettings/userSettingsType';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserSetting } from 'selectors/userSettings';
import { noValue } from 'utils/search';
import { useAuth } from './useAuth';
import licenseType from 'models/licenseType';

function useDefaultSearchCategories(filters, setFilters, isSearchLoading) {
  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);
  const defaultSearchCategories = useMemo(
    () =>
      hasIndustryLicense
        ? {
            funding: true,
            tradeshows: true,
            publications: true,
            clinicalTrials: true,
            profile: true,
          }
        : {
            funding: true,
            tradeshows: true,
            publications: true,
            clinicalTrials: true,
          },
    [hasIndustryLicense]
  );

  const defaultSearchCategoriesFromSettings = useSelector(
    getUserSetting(getUserSettingsFromState.DefaultSearchCategories)
  );

  const searchCategories = useMemo(() => {
    if (defaultSearchCategoriesFromSettings) {
      return defaultSearchCategoriesFromSettings.reduce((acc, current) => {
        acc[current] = true;
        return acc;
      }, {});
    }

    return defaultSearchCategories;
  }, [defaultSearchCategories, defaultSearchCategoriesFromSettings]);

  const setFiltersLocal = useRef(setFilters);
  useEffect(() => {
    if (noValue(filters)) {
      setFiltersLocal.current(searchCategories);
    }

    if (!hasIndustryLicense && filters?.profile) {
      const { profile, ...noProfileFilters } = filters;
      setFiltersLocal.current(noProfileFilters);
    }
  }, [filters, hasIndustryLicense, searchCategories]);

  const hideDefaultCategoriesError =
    useSelector(
      getUserSetting(
        getUserSettingsFromState.DisplayDefaultCategoriesErrorMessage
      )
    ) === false;

  const [showDefaultCategoriesError, setShowDefaultCategoriesError] = useState(
    false
  );
  useEffect(() => {
    if (hideDefaultCategoriesError || !isSearchLoading) {
      return;
    }

    const selectedCategoryFilters = Object.keys(filters)
      .filter(k => filters[k])
      .sort()
      .join(',');
    const defaultCategoryFilters = Object.keys(searchCategories)
      .filter(k => searchCategories[k])
      .sort()
      .join(',');
    setShowDefaultCategoriesError(
      selectedCategoryFilters !== defaultCategoryFilters
    );
  }, [searchCategories, filters, isSearchLoading, hideDefaultCategoriesError]);

  return [showDefaultCategoriesError, setShowDefaultCategoriesError];
}

export default useDefaultSearchCategories;
