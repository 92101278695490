import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pane, Loader, Button, Tooltip } from 'components/common';
import { useGet, usePost, useAuth } from 'hooks';

import Unauthed from './Unauthed';
import Table from './Table';
import Modal from './Modal';
import { getCrmSaveUrl, getCrmDataUrl, getCrmDisconnectUrl } from './helpers';
import { crmTypes } from 'utils/helpers/crm';
import { reloadCrmConnections } from 'actions/other';
import { getReloadCrmConnections } from '../../../selectors/other';

const Actions = ({ mappings, fetchData, crmType }) => {
  const [{ res, loading }, saveChanges] = usePost({
    url: getCrmSaveUrl(crmType),
  });
  const [{ res: disconnectData }, disconnect] = usePost({
    url: getCrmDisconnectUrl(crmType),
  });

  const { checkUser, user } = useAuth();

  useEffect(() => {
    if (res || disconnectData) {
      fetchData();
      checkUser();
    }
  }, [res, disconnectData, fetchData, checkUser]);

  const handleSave = () => {
    saveChanges({
      mappings,
      crmType: crmType,
    });
  };

  return (
    <div>
      <Tooltip
        title={`This will disconnect your personal account from ${crmType}`}
      >
        <Button
          size='small'
          type='danger'
          ghost
          style={{ marginRight: 10 }}
          onClick={() => disconnect()}
        >
          Disconnect {crmType}
        </Button>
      </Tooltip>
      {user.isAccountManager && (
        <Tooltip title='These mapping changes will be applied to all users on this account'>
          <Button
            size='small'
            type='primary'
            disabled={loading}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const CRMIntegration = ({ crmType }) => {
  const dispatch = useDispatch();
  const [{ res, loading }, fetchData] = useGet({
    url: getCrmDataUrl(crmType),
    onMount: true,
  });
  const [field, setField] = useState();
  const [mappings, setMappings] = useState();
  const reload = useSelector(getReloadCrmConnections) || false;

  useEffect(() => {
    res && setMappings(res.mappings);
  }, [res]);

  useEffect(() => {
    if (reload) {
      fetchData();
      dispatch(reloadCrmConnections(false));
    }
  }, [reload, dispatch, fetchData]);

  const updateMapping = (expression, { mapping, sfField }) => {
    let current = mappings;
    if (mapping.expression === expression) {
      return setField();
    }
    const existing = current.find(m => m.crmField === mapping.crmField);
    if (existing) {
      if (expression) {
        existing.expression = expression;
      } else {
        current = current.filter(c => c.crmField !== mapping.crmField);
      }
    } else {
      current.push({
        crmType: crmType,
        crmField: sfField.name,
        crmFieldType: sfField.type,
        crmFieldDisplayName: sfField.displayName,
        expression,
        isEnabled: true,
      });
    }

    setMappings([...current]);
    setField();
  };

  const toggleMappingStatus = ({ mapping, sfField }) => {
    let current = mappings;

    const existing = current.find(m => m.crmField === mapping.crmField);
    if (existing) {
      if (existing.expression) {
        existing.isEnabled = !existing.isEnabled;
      } else {
        current = current.filter(c => c.crmField !== mapping.crmField);
      }
    } else {
      current.push({
        crmType: crmType,
        crmField: sfField.name,
        crmFieldType: sfField.type,
        crmFieldDisplayName: sfField.displayName,
        isEnabled: false,
      });
    }

    setMappings([...current]);
    setField();
  };

  if (loading) {
    return <Loader />;
  }

  if (res && !res.isAuthenticated)
    return <Unauthed url={res.crmAuthUrl} crmType={crmType} />;

  return (
    <Pane
      title={crmType}
      headerRight={
        <Actions mappings={mappings} fetchData={fetchData} crmType={crmType} />
      }
    >
      <Table
        data={res}
        mappings={mappings}
        setField={setField}
        researcherFields={res.researcherFields}
        clearRow={r => updateMapping('', r)}
        toggleEnabled={r => toggleMappingStatus(r)}
      />
      {field && (
        <Modal
          field={field}
          setField={setField}
          onOk={updateMapping}
          researcherFields={res.researcherFields}
        />
      )}
    </Pane>
  );
};

const CRMIntegrations = () => {
  const { user } = useAuth();
  const types = [];
  if (user.canPushToSalesforce) types.push(crmTypes.SALESFORCE);
  if (user.canPushToHubspot) types.push(crmTypes.HUBSPOT);
  return (
    <Pane title='CRM Integrations'>
      {types.map((type, i) => (
        <CRMIntegration crmType={type} key={i} />
      ))}
    </Pane>
  );
};

export default CRMIntegrations;
