import { Fragment } from 'react';
import { Link, Clamp, PublicationAuthors } from 'components/common';
import { format } from 'utils/date';
import { formatCurrency } from 'utils/number';
import { renderLink } from './helpers';
import { stripHtml } from 'utils/string';

export const perPage = [
  { value: 5, label: '5 per page' },
  { value: 10, label: '10 per page' },
  { value: 25, label: '25 per page' },
  { value: 50, label: '50 per page' },
];

export const getConfigs = hasOrgLicense => ({
  funding: {
    headers: [
      { label: 'Funded Project Title', minWidth: 200, maxContentWidth: 350 },
      { label: 'Date Awarded', minWidth: 120 },
      { label: 'Funding (USD)', minWidth: 120 },
      { label: 'Funding Starts', minWidth: 120 },
      { label: 'Funding Ends', minWidth: 120 },
      { label: 'Description', minWidth: 200, maxContentWidth: 400 },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.id}`}>
        <div>
          <Link to={`/project/${r.id}`}>
            <Clamp text={r.projectTitle} />
          </Link>
        </div>
        <div>{format(r.projectAwardDate)}</div>
        <div style={{ justifyContent: 'flex-end' }}>
          {r.projectFundingAmountUsd
            ? `$${formatCurrency(r.projectFundingAmountUsd)}`
            : ''}
        </div>
        <div>{format(r.projectStartDate)}</div>
        <div>{format(r.projectEndDate)}</div>
        <div>
          <Clamp lines={2} text={r.projectDescription} />
        </div>
      </Fragment>
    ),
  },
  posters: {
    headers: [
      { label: 'Tradeshow', minWidth: 180, maxContentWidth: 350 },
      { label: 'Year', minWidth: 40 },
      { label: 'Title', minWidth: 300, maxContentWidth: 500 },
      { label: 'Session Type', minWidth: 80 },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.tradeShowId}-${r.posterId}-${i}`}>
        <div>{r.name}</div>
        <div>{r.year}</div>
        <div>
          <Link to={`/poster/${r.posterId}`}>{r.title}</Link>
        </div>
        <div>{r.sessionType !== 'Uncategorised' ? r.sessionType : '-'}</div>
      </Fragment>
    ),
  },
  trials: {
    headers: [
      { label: 'NCT ID', minWidth: 100 },
      { label: 'Role', minWidth: 100 },
      { label: 'First Posted', minWidth: 100 },
      { label: 'Study Type', minWidth: 80 },
      { label: 'Title', minWidth: 250, maxContentWidth: 350 },
      { label: 'Status', minWidth: 80 },
      { label: 'Phase', minWidth: 80 },
      { label: 'Sponsor', minWidth: 100 },
    ],
    createRow: (r, i) => (
      <Fragment key={r.nctId}>
        <div>
          <Link to={`/trial/${r.nctId}`}>{r.nctId}</Link>
        </div>
        <div>{r.researcherRole || 'Other'}</div>
        <div>{format(r.studyFirstPostedDate)}</div>
        <div>{r.studyType}</div>
        <Clamp text={r.briefTitle} lines={2} />
        <div>{r.overallStatus}</div>
        <div>{r.phase}</div>
        {!hasOrgLicense && <div>{r.leadSponsor}</div>}
        {hasOrgLicense && r.leadSponsorSuperOrg && (
          <Link to={`/organisation/${r.leadSponsorSuperOrg}`}>
            {r.leadSponsor}
          </Link>
        )}
        {hasOrgLicense && !r.leadSponsorSuperOrg && <div>{r.leadSponsor}</div>}
      </Fragment>
    ),
  },
  publications: {
    headers: [
      { label: 'Publication Title', minWidth: 150, maxContentWidth: 450 },
      { label: 'Authors', minWidth: 100 },
      { label: 'Journal/Preprints', minWidth: 150, maxContentWidth: 350 },
      { label: 'Date Published', minWidth: 100 },
      { label: 'Citations', minWidth: 50 },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.citationId}-${i}`}>
        <div>
          <Link to={`/publication/${r.citationId}`}>
            <Clamp text={r.title} title={stripHtml(r.title)} isHtml />
          </Link>
        </div>
        <PublicationAuthors
          authors={r.authors}
          authorCount={r.authors.length}
        />
        <Clamp text={r.journal} />
        <div>{format(r.publishedDate)}</div>
        <div>{r.cited}</div>
      </Fragment>
    ),
  },
  network: {
    headers: [
      { label: 'Name', key: 'name', minWidth: 100 },
      { label: 'Collaborations', key: 'collabCount', minWidth: 100 },
      { label: 'Last Collaboration', key: 'lastCollab', minWidth: 100 },
      { label: 'Organisation', key: 'organisation', minWidth: 150 },
      { label: 'Location', key: 'organisationLocation', minWidth: 200 },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.title}-${i}`}>
        {renderLink(r)}
        <div>{r.collabCount}</div>
        <div>{format(r.lastCollab)}</div>
        <div>{r.organisation || '-'}</div>
        <div>{r.organisationLocation}</div>
      </Fragment>
    ),
  },
});
