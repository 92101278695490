import styled from 'styled-components';

import { colours } from 'utils/theme';

const Container = styled.div`
  margin-bottom: 5px;
`;

const Detail = ({
  children,
  label,
  className,
  style,
  headerStyle = {},
  textTransform = 'uppercase',
  isHtml,
}) => {
  return (
    <Container className={className} style={style}>
      <div
        style={{
          fontSize: 11,
          textTransform: textTransform,
          fontWeight: 'bold',
          color: colours.grey,
          ...headerStyle,
        }}
      >
        {label}
      </div>
      {isHtml ? (
        <span dangerouslySetInnerHTML={{ __html: children || '-' }} />
      ) : (
        children || '-'
      )}
    </Container>
  );
};

Detail.defaultProps = {
  isHtml: false,
};

export default Detail;
