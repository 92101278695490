import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;
const Content = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${p => p.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Clamp = ({
  lines,
  text,
  className,
  title,
  overflowComponent,
  isHtml,
  ...rest
}) => {
  return (
    <Container title={title ? title : text} className={className}>
      <Content lines={lines} {...rest}>
        {isHtml ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text}
      </Content>
    </Container>
  );
};

Clamp.defaultProps = {
  lines: 1,
  isHtml: false,
};

export default Clamp;
