import styled from 'styled-components';

export const SearchAreaContainer = styled.div`
  margin-top: ${p => p.theme.gutter};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const ActionButtons = styled.div`
  display: flex;
  margin-left: 10px;
  > * {
    margin-right: 10px;
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: row-reverse;
    > * {
      margin-left: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
      :first-child {
        margin-right: 0px;
      }
      :last-child {
        margin-bottom: 0px;
      }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
`;

export const ActionToggles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Stats = styled.div`
  .gridParent {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(12.5% - ${p => p.theme.gutter}), 1fr)
    );
    gap: ${p => p.theme.gutter};
    margin-top: ${p => p.theme.gutter};

    ${p => p.theme.breakpoint.tablet} {
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc(25% - ${p => p.theme.gutter}), 1fr)
      );
    }
  }
`;

export const ResultsArea = styled.div`
  display: flex;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const AlertContentContainer = styled.div`
  > span {
    cursor: pointer;
    color: #2d9aff;
  }
`;
