import React from 'react';
import { Category } from 'components/common';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setUserSetting } from 'actions/userSettings';
import searchCategories, {
  searchCategoriesInfo,
} from 'models/searchCategories';
import licenseType from 'models/licenseType';
import { getUserSettingsFromState } from '../userSettingsType';
import { useAuth } from 'hooks';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const allValues = Object.keys(searchCategories).map(c => searchCategories[c]);

function DefaultSearchCategories({ value = allValues }) {
  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);

  const dispatch = useDispatch();
  const onChange = category => {
    var selected = [...value];
    if (selected.includes(category)) {
      selected = selected.filter(s => s !== category);
    } else {
      selected.push(category);
    }

    dispatch(
      setUserSetting(getUserSettingsFromState.DefaultSearchCategories, selected)
    );
  };

  const availableCategories = allValues.filter(
    c => c !== searchCategories.thirdPartyData || hasIndustryLicense
  );
  return (
    <Container>
      {availableCategories.map(c => (
        <Category
          key={c}
          helpText={searchCategoriesInfo[c].header}
          icon={searchCategoriesInfo[c].icon}
          value={value.includes(c)}
          onChange={() => onChange(c)}
        />
      ))}
    </Container>
  );
}

DefaultSearchCategories.propTypes = {};

export default DefaultSearchCategories;
