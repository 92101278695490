import React, { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setWorkItemPageNumber,
  setWorkItemPageSize,
  setWorkItemSort,
} from 'actions/organisation';
import {
  Clamp,
  Link,
  ResearcherEmail,
  Button,
  SparklesIcon,
  HighlightPopover,
} from 'components/common';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';
import { getDocumentIdentifierField } from 'components/common/HighlightPopover/helpers';
import {
  getOrganisationWorkItemIsLoading,
  getOrganisationWorkItemPageNumber,
  getOrganisationWorkItemPageSize,
  getOrganisationWorkItems,
  getOrganisationWorkItemSort,
  getOrganisationWorkItemHighlight,
} from 'selectors/organisation';
import { format, timeDiff } from 'utils/date';
import { getLinkedInNetwork } from '..';
import { makeHttpsUrl } from 'utils/helpers/externalLink';
import { stripHtml } from 'utils/string';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';
import AiMessage from 'components/AiMessage';

import { Table } from '../styled';

const emptyText = '';

export default function OrganisationItems({
  organisation,
  handleSearch,
  loading,
  parentRef,
}) {
  const { hasLicense } = useAuth();
  const hasPermissionToAI = hasLicense(
    [licenseType.academic, licenseType.industry],
    true
  );

  const [aiMessage, setAiMessage] = useState(null);

  const dispatch = useDispatch();
  const sort = useSelector(getOrganisationWorkItemSort(organisation.id));
  const pageNumber = useSelector(
    getOrganisationWorkItemPageNumber(organisation.id)
  );
  const pageSize = useSelector(
    getOrganisationWorkItemPageSize(organisation.id)
  );
  const isLoading =
    useSelector(getOrganisationWorkItemIsLoading(organisation.id)) || loading;
  const workItems = useSelector(getOrganisationWorkItems(organisation.id));
  const highlightQuery = useSelector(
    getOrganisationWorkItemHighlight(organisation.id)
  );
  const handleSortChange = sortKey => {
    dispatch(
      setWorkItemSort(organisation.id, {
        field: sortKey,
        direction: !sort
          ? 'asc'
          : sortKey === !sort.field
          ? 'asc'
          : sort.direction === 'asc'
          ? 'desc'
          : 'asc',
      })
    );
    handleSearch();
  };

  const handlePageSize = size => {
    dispatch(setWorkItemPageSize(organisation.id, size));
    handleSearch();
  };

  const handlePageNumber = number => {
    dispatch(setWorkItemPageNumber(organisation.id, number));
    handleSearch();
  };

  const makeCustomLinkedInSearchUrl = useCallback(
    item =>
      `http://www.google.com/search?q=${item.fullName} ${organisation.organisationName} site:linkedin.com`,
    [organisation.organisationName]
  );

  const perPage = useMemo(
    () => [
      { value: 50, label: '50 per page' },
      { value: 100, label: '100 per page' },
      { value: 200, label: '200 per page' },
    ],
    []
  );

  const headers = useMemo(
    () =>
      [
        { label: 'Category', key: 'Category.keyword', minWidth: 80 },
        {
          label: 'Title',
          key: 'Title.keyword',
          minWidth: 200,
          maxContentWidth: 250,
        },
        {
          label: 'Key Information',
          key: 'KeyInformation',
          minWidth: 100,
          maxContentWidth: 250,
        },
        { label: 'Date', key: 'Date', minWidth: 100 },
        { label: 'Name', key: 'FullName.keyword', minWidth: 100 },
        {
          label: 'Contact',
          key: 'Email.keyword',
          minWidth: 150,
          maxContentWidth: 250,
        },
        hasPermissionToAI && { key: 'ai', minWidth: 150 },
        { label: 'Job Title', key: 'JobTitle.keyword', minWidth: 100 },
        {
          label: 'Location',
          key: 'City.keyword',
          minWidth: 100,
        },
        { label: 'Time at Company', key: 'JobStartDate', minWidth: 100 },
        { label: 'Last Activity', key: 'LastActivityDate', minWidth: 100 },
        { label: 'Matches', key: 'matches', minWidth: 100 },
      ].filter(Boolean),
    [hasPermissionToAI]
  );

  const config = useMemo(
    () => ({
      headers: headers,
      createRow: r => {
        const location = [r.city, r.state, r.country]
          .filter(Boolean)
          .join(', ');
        let workItemlink = getWorkItemLink(r);

        return (
          <>
            <div>{r.category || emptyText}</div>
            <div>
              {r.title && workItemlink ? (
                <Link size='medium' to={workItemlink} showIconHint>
                  <div
                    title={
                      r.category === 'Publications'
                        ? stripHtml(r.title)
                        : r.title
                    }
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {r.title}
                  </div>
                </Link>
              ) : r.title ? (
                <Clamp text={`${r.title}`} lines={1} />
              ) : (
                emptyText
              )}
            </div>
            <div>{r.keyInformation || emptyText}</div>
            <div>{format(r.date) || emptyText}</div>
            <div>
              {r.category !== 'Profile' && (
                <div>
                  {r.fullName ? (
                    <Link
                      size='medium'
                      to={`/researcher/${r.sciLeadsSuperResearcherId}`}
                      showIconHint
                    >
                      {r.fullName}
                    </Link>
                  ) : (
                    emptyText
                  )}
                </div>
              )}

              {r.category === 'Profile' && (
                <div>
                  <span>
                    <span style={{ margin: '5px' }}> {r.fullName}</span>
                  </span>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {(r.linkedIn || r.fullName) && (
                <SocialMediaLink
                  network={getLinkedInNetwork()}
                  title={
                    r.linkedIn ? 'View on LinkedIn' : 'Search for LinkedIn'
                  }
                  url={
                    r.linkedIn
                      ? makeHttpsUrl(r.linkedIn)
                      : makeCustomLinkedInSearchUrl(r)
                  }
                  isMuted={!r.linkedIn}
                  logDescription={{
                    entityType: 'Researcher',
                    location: 'OrganisationPage:Activity',
                  }}
                />
              )}
              <div style={{ marginLeft: '10px' }}></div>
              <ResearcherEmail
                email={r.email}
                dataToPost={{
                  sciLeadsResearcherId: r.sciLeadsSuperResearcherId,
                  location: 'OrganisationPage:Activity',
                }}
                emailStatusCategory={r.emailStatusCategory}
                allowFind
                buttonSize='small'
                hideIcon
                style={{
                  marginLeft: 10,
                }}
              />
            </div>
            {hasPermissionToAI && (
              <Button
                type='primary'
                size='small'
                onClick={e => {
                  e?.stopPropagation();
                  setAiMessage({
                    id: r.sciLeadsSuperResearcherId,
                    organisationCity: r.city,
                  });
                }}
                icon={<SparklesIcon style={{ marginRight: '6px' }} />}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                AI Message
              </Button>
            )}
            <div>
              {r.jobTitle ? (
                <Clamp text={`${r.jobTitle}`} lines={1} />
              ) : (
                emptyText
              )}
            </div>
            <div>{location || emptyText}</div>
            <div>{timeDiff(r.jobStartDate, new Date()) || emptyText}</div>
            <div>{format(r.lastActivityDate) || emptyText}</div>
            <div>
              <HighlightPopover
                parentRef={parentRef}
                entityId={r[getDocumentIdentifierField(r.category)]}
                entityType={r.category}
                highlightQuery={highlightQuery}
              />
            </div>
          </>
        );
      },
    }),
    [
      makeCustomLinkedInSearchUrl,
      hasPermissionToAI,
      headers,
      parentRef,
      highlightQuery,
    ]
  );

  return (
    organisation && (
      <>
        <Table
          config={config}
          data={workItems?.items || []}
          loading={isLoading}
          onChangePageNumber={handlePageNumber}
          onChangePageSize={handlePageSize}
          pageSize={pageSize ?? 50}
          pageNumber={pageNumber ?? 0}
          resultSize={workItems?.totalCount || 0}
          onSortChange={handleSortChange}
          paginate
          sortable
          className={'researchers-table'}
          sort={sort?.field}
          sortDir={sort?.direction}
          pageSizeOptions={perPage}
        />
        {aiMessage && (
          <AiMessage
            requestLocation='org-activity'
            researcherId={aiMessage.id}
            city={aiMessage.organisationCity}
            onClose={() => setAiMessage(null)}
            requestTradeshows
          />
        )}
      </>
    )
  );
}

export const getWorkItemLink = row => {
  if (row.category === 'Publications' && row.citationId) {
    return `/publication/${row.citationId}`;
  } else if (row.category === 'Tradeshows' && row.sciLeadsPosterId) {
    return `/poster/${row.sciLeadsPosterId}`;
  } else if (row.category === 'ClinicalTrials' && row.nctId) {
    return `/trial/${row.nctId}`;
  } else if (row.category === 'Funding' && row.sciLeadsAwardId) {
    return `/project/${row.sciLeadsAwardId}`;
  }

  return '';
};
