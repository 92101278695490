const searchCategories = {
  funding: 'funding',
  tradeshows: 'tradeshows',
  publications: 'publications',
  clinicalTrials: 'clinicalTrials',
  profile: 'profile',
};

export const searchCategoriesInfo = {
  [searchCategories.funding]: {
    header: 'Funding',
    icon: 'award',
  },
  [searchCategories.tradeshows]: {
    header: 'Tradeshows',
    icon: 'image',
  },
  [searchCategories.publications]: {
    header: 'Publications',
    icon: 'book',
  },
  [searchCategories.clinicalTrials]: {
    header: 'Clinical Trials',
    icon: 'trial',
  },
  [searchCategories.profile]: {
    header: '3rd Party Data',
    icon: 'magnifyingGlass',
  },
};

export default searchCategories;
