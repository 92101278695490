import { Fragment } from 'react';

import { Button, Tooltip } from 'components/common';

import { StyledTable, ColoredField } from './styled';
import { useAuth } from 'hooks';

const CRMIntegrations = ({
  data,
  mappings = [],
  setField,
  toggleEnabled,
  researcherFields,
  clearRow,
}) => {
  const { user } = useAuth();

  let headers = ['CRM Field', 'SciLeads Field', 'Status'];
  if (user.isAccountManager) headers.push('');

  const config = {
    headers: headers,
    createRow: (r, i) => {
      return (
        <Fragment key={i}>
          <div>{r.sfName}</div>
          <ColoredField isDefault={r.slField.isDefault}>
            {r.slName}
          </ColoredField>
          <ColoredField isDefault={false}>
            {r.mapping.isEnabled ? 'Enabled' : 'Disabled'}
          </ColoredField>
          {user.isAccountManager && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip
                title={
                  r.sfField.isRequired
                    ? 'This field is required in your CRM, and cannot be disabled.'
                    : 'Enabled fields are pushed to CRM systems. Disabled fields are ignored.'
                }
              >
                <span>
                  <Button
                    disabled={r.sfField.isRequired}
                    size='small'
                    onClick={() => toggleEnabled(r)}
                    style={{ marginRight: 5 }}
                  >
                    {r.mapping.isEnabled ? 'Disable' : 'Enable'}
                  </Button>
                </span>
              </Tooltip>
              {!r.slField.isDefault && (
                <Button
                  onClick={() => clearRow(r)}
                  size='small'
                  style={{ marginRight: 5 }}
                >
                  Clear
                </Button>
              )}
              <Button
                size='small'
                onClick={() => setField(r)}
                style={{ marginRight: 5 }}
              >
                Edit
              </Button>
            </div>
          )}
        </Fragment>
      );
    },
  };

  const mappedData = data.allCrmFields.map(sfField => {
    const defaultField = {
      displayName: 'default',
      isDefault: true,
    };
    const defaultMapping = {
      expression: '',
      isDefault: true,
      isEnabled: true,
    };
    let slField = defaultField;

    const mapping = mappings.find(m => m.crmField === sfField.name);

    if (mapping && mapping.expression) {
      const foundField = researcherFields.find(
        f => f.name === mapping.expression.replace('{{', '').replace('}}', '')
      );
      slField = foundField || { displayName: mapping.expression };
    }

    const sfName = sfField.displayName;
    const slName = slField.displayName;

    return {
      sfField,
      slField,
      sfName,
      slName,
      mapping: mapping || defaultMapping,
    };
  });

  return <StyledTable config={config} data={mappedData} />;
};

export default CRMIntegrations;
